/* Styles from the GreenSock website */
body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  background: url('../assets/bg.png');
  padding-bottom: 80px;
}

.wrapper {
  margin: 0 auto;
  width: 612px;
  text-align: center;
}

.speech-wrapper{
  padding: 15px 20px;
  margin: 0 auto;
  width: 600px;
  .bubble{
    text-align: left;
    width: 400px;
    height: auto;
    display: block;
    background: #f5f5f5;
    border-radius: 4px;
    box-shadow: 2px 8px 5px rgba(0, 0, 0, 0.4);
    position: relative;
    margin: 0 0 25px;
    &.alt{
      margin: 0 0 25px 150px;
    }
    .txt{
      padding: 8px 55px 8px 14px;
      .name{
        font-weight: 600;
        font-size: 12px;
        margin: 0 0 4px;
        color: #3498db;
        span{
          font-weight: normal;
          color: #b3b3b3;
        }
        &.alt{
          color: #2ecc71;
        }
      }
      .message{
        font-size: 12px;
        margin: 0;
        color: #2b2b2b;
      }
      .timestamp{
        font-size: 11px;
        position: absolute;
        bottom: 8px;
        right: 10px;
        text-transform: uppercase; color: #999
      }
    }
    .bubble-arrow {
      position: absolute;
      width: 0;
      bottom:42px;
      left: -16px;
      height: 0;
      &.alt{
        right: -2px;      
        bottom: 40px;
        left: auto;
      }
    }
    .bubble-arrow:after {
      content: "";
      position: absolute;
      border: 0 solid transparent;
      border-top: 9px solid #f5f5f5;
      border-radius: 0 20px 0;
      width: 15px;
      height: 30px;
      transform: rotate(145deg);
    }
    .bubble-arrow.alt:after {
      transform: rotate(45deg) scaleY(-1);
    }
  }
}

.header-section {
  margin: 200px auto 50px auto;
  color: rgba(251, 93, 193, 1);
  text-align: center;
}

.ml16 {
  font-weight: 800;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  overflow: hidden;
}

.ml16 .letter {
  display: inline-block;
  line-height: 0.5em;
}

.header {
  font: 42px "Source Sans Pro", "Helvetica Neue", Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 40px;
  font-weight: 500;
  color: rgba(251, 93, 193, 1);
}

.headerf {
  font: 42px "Source Sans Pro", "Helvetica Neue", Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  font-weight: 500;
  color: rgba(251, 93, 193, 1);
}

.headerf .lf {
  flex: 1;
}

.headerf .rg {
  flex: 1;
}

.nm {
  font-weight: 800;
  text-shadow: 2px 2px 4px black;
}

img {
  margin: 30px auto;
  max-width: 600px;
}

#reveal1 {
  opacity: 0;
  -webkit-transform: scale(0.9);
     -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
       -o-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: all 1s ease-in-out;
     -moz-transition: all 1s ease-in-out;
      -ms-transition: all 1s ease-in-out;
       -o-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out;
}
#reveal1.visible {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
}

.lside {
  opacity: 0;
  -webkit-transform: translateX(-40px);
     -moz-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
       -o-transform: translateX(-40px);
          transform: translateX(-40px);
  -webkit-transition: all 0.6s ease-out;
     -moz-transition: all 0.6s ease-out;
      -ms-transition: all 0.6s ease-out;
       -o-transition: all 0.6s ease-out;
          transition: all 0.6s ease-out;
}
.lside.visible {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
}

.rside {
  opacity: 0;
  -webkit-transform: translateX(40px);
     -moz-transform: translateX(40px);
      -ms-transform: translateX(40px);
       -o-transform: translateX(40px);
          transform: translateX(40px);
  -webkit-transition: all 0.6s ease-out;
     -moz-transition: all 0.6s ease-out;
      -ms-transition: all 0.6s ease-out;
       -o-transition: all 0.6s ease-out;
          transition: all 0.6s ease-out;
}
.rside.visible {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
}

.ml15 {
  font-weight: 800;
  font-size: 3.2em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.ml15 .word {
  display: inline-block;
  line-height: 0.5em;
}