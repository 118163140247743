body {
  background: url("bg.28148d9d.png");
  padding-bottom: 80px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.wrapper {
  width: 612px;
  text-align: center;
  margin: 0 auto;
}

.speech-wrapper {
  width: 600px;
  margin: 0 auto;
  padding: 15px 20px;
}

.speech-wrapper .bubble {
  text-align: left;
  width: 400px;
  height: auto;
  background: #f5f5f5;
  border-radius: 4px;
  margin: 0 0 25px;
  display: block;
  position: relative;
  box-shadow: 2px 8px 5px #0006;
}

.speech-wrapper .bubble.alt {
  margin: 0 0 25px 150px;
}

.speech-wrapper .bubble .txt {
  padding: 8px 55px 8px 14px;
}

.speech-wrapper .bubble .txt .name {
  color: #3498db;
  margin: 0 0 4px;
  font-size: 12px;
  font-weight: 600;
}

.speech-wrapper .bubble .txt .name span {
  color: #b3b3b3;
  font-weight: normal;
}

.speech-wrapper .bubble .txt .name.alt {
  color: #2ecc71;
}

.speech-wrapper .bubble .txt .message {
  color: #2b2b2b;
  margin: 0;
  font-size: 12px;
}

.speech-wrapper .bubble .txt .timestamp {
  text-transform: uppercase;
  color: #999;
  font-size: 11px;
  position: absolute;
  bottom: 8px;
  right: 10px;
}

.speech-wrapper .bubble .bubble-arrow {
  width: 0;
  height: 0;
  position: absolute;
  bottom: 42px;
  left: -16px;
}

.speech-wrapper .bubble .bubble-arrow.alt {
  bottom: 40px;
  left: auto;
  right: -2px;
}

.speech-wrapper .bubble .bubble-arrow:after {
  content: "";
  width: 15px;
  height: 30px;
  border: 0 solid #0000;
  border-top: 9px solid #f5f5f5;
  border-radius: 0 20px;
  position: absolute;
  transform: rotate(145deg);
}

.speech-wrapper .bubble .bubble-arrow.alt:after {
  transform: rotate(45deg)scaleY(-1);
}

.header-section {
  color: #fb5dc1;
  text-align: center;
  margin: 200px auto 50px;
}

.ml16 {
  text-transform: uppercase;
  letter-spacing: .5em;
  font-size: 1.5em;
  font-weight: 800;
  overflow: hidden;
}

.ml16 .letter {
  line-height: .5em;
  display: inline-block;
}

.header {
  width: 100%;
  color: #fb5dc1;
  padding: 40px;
  font: 500 42px Source Sans Pro, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.headerf {
  width: 100%;
  color: #fb5dc1;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font: 500 42px Source Sans Pro, Helvetica Neue, Arial, Helvetica, sans-serif;
  display: flex;
}

.headerf .lf, .headerf .rg {
  flex: 1;
}

.nm {
  text-shadow: 2px 2px 4px #000;
  font-weight: 800;
}

img {
  max-width: 600px;
  margin: 30px auto;
}

#reveal1 {
  opacity: 0;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  transform: scale(.9);
}

#reveal1.visible {
  opacity: 1;
  transform: none;
}

.lside {
  opacity: 0;
  -o-transition: all .6s ease-out;
  transition: all .6s ease-out;
  transform: translateX(-40px);
}

.lside.visible {
  opacity: 1;
  transform: none;
}

.rside {
  opacity: 0;
  -o-transition: all .6s ease-out;
  transition: all .6s ease-out;
  transform: translateX(40px);
}

.rside.visible {
  opacity: 1;
  transform: none;
}

.ml15 {
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 3.2em;
  font-weight: 800;
}

.ml15 .word {
  line-height: .5em;
  display: inline-block;
}

/*# sourceMappingURL=index.671a6a91.css.map */
